import React from 'react';

const AppForm = ({formId}) => {
  return (
    <div>
      show form: {formId}
    </div>
  );
};

export default AppForm;