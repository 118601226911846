import AppForm from './components/form/AppForm';
import {useParams} from 'react-router-dom';

const AppFormPage = () => {
  const {formId} = useParams();

  return (
      <AppForm formId={formId} />
  );
};

export default AppFormPage;
